.span-button {
    outline: transparent;
    position: relative;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid rgb(0, 120, 212);
    border-radius: 2px;
    cursor: pointer;
    padding: 0px 16px;
    text-decoration: none;
    min-width: 80px;
    height: 32px;
    background-color: rgb(0, 120, 212);
    color: rgb(255, 255, 255);
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.span-button:hover {
    background-color: rgb(16, 110, 190);
    border: 1px solid rgb(16, 110, 190);
    color: rgb(255, 255, 255);
}
.error-message {
    animation-name: css-1, css-14;
    animation-duration: 0.367s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
    animation-fill-mode: both;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin: 0px;
    padding-top: 5px;
    display: flex;
    align-items: center;
}