.button {
    background-color: #fff;
    padding: 0.75rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    position: relative;
    border: 1px solid #eee;
    outline: none;
    max-width: 14rem;
    cursor: pointer;
  }

  .button-selected {
    border: 2px solid #2563eb;
  }
  
  .button-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    color: #1f2937;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid rgba(209, 213, 219, 0.75);
    width: 100%;
  }
  
  .avatar {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5rem;
    border-radius: 9999px;
  }
  
  .button-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .label {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    line-height: 1;
    color: #059669;
    background-color: #d1fae5;
    border-radius: 0.375rem;
  }
  
  .price {
    margin-left: auto;
    font-size: 0.75rem;
    color: #6b7280;
  }
  