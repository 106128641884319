.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  /* min-height: 100vh; */
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  margin: 0;
}

header, footer {
  flex: none;
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

main {
  flex: 1;
}

.dashboard-tile {
  background-color: #fff;
  padding: 16px 8px 8px;
  box-shadow: 0px 13px 19px -7px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
}

.unauthenticated {
  height: 83vh;
  align-items: center;
  display: flex;
  justify-content: center;
}

@media print {
  body {
    visibility: hidden;
  }

  .printable {
    visibility: visible;
    display: flex;
    flex-direction: column;
  }

  div>div:not(.printable) {
    visibility: visible;
  }

  div+div:not(.printable) {
    visibility: hidden;
  }
}

div[role="grid"] {
  height: 100% !important;
}